<template>
    <div class="container">
      <tab-header :headerList="boss.anaylsisList" :currentTab="0" @changeTabPage="changeTabPage"></tab-header>
      <!--项目类型选择-->
      <nav-select :selectList="selectList" @chooseNavItem="chooseNavItem"></nav-select>
      <!--时间选择-->
      <time-button @timeChange="timeChange"></time-button>
      <!--排名列表头部-->
      <ul class="rank-header">
          <li v-for="(item,index) in rankTitle" :key="index" @click="handleRank(index)">{{item}}
              <i class="iconfont">&#xe602;</i>
          </li>
      </ul>
      <div class="list-container">
          <div class="main-content"  v-if="projectList&&projectList.length">
              <!--类型排名列表-->
            <ul class="project-type-list" v-if="sortType==2">
                  <li v-for="(item,index) in projectList" :key="index">
                     <div class="project-item type-box"  v-show="item.all_count_rate">

                        <div class="type-column">
                            <p>{{item.service_type_name}}</p>
                        </div>

                      <div class="order-colume">
                            <h3>{{item.all_order_count|valueKbit}}</h3>
                            <div class="order-bar">
                                <p class="count-rate">{{item.all_count_rate}}</p>
                                <p class="progress-bar">
                                    <span :style="{width:item.all_count_rate}"></span>
                                </p>
                            </div>
                        </div>

                       <div class="revenue-colume">
                            <h3><span>￥</span>{{item.all_revenue_count|valueKbit}}</h3>
                            <div class="revenue-bar">
                                <p class="count-rate">{{item.all_revenue_rate}}</p>
                                <p class="progress-bar">
                                    <span :style="{width:item.all_revenue_rate}"></span>
                                </p>
                            </div>
                        </div>
                      </div>
                      <project-list :projectList="item.projects"></project-list>
                  </li>
              </ul>
              <!--非类型排名列表-->
              <project-list :projectList="projectList" v-else></project-list>
          </div>
          <div class="main-content" v-else>
              <empty-box ></empty-box>
          </div>
          <daqi-copyright></daqi-copyright>
      </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import table from '@/api/table'

export default {
  name:"projects-analysis",
  data(){
    return{
      selectList:['主项','附项','商品','套餐'],
      rankTitle:['类型排序','点数/总点数占比','净营业额/占比'],
      categoryId:1, //项目主类ID 1主项；2附项；3商品；4套餐
      timeParams: null, //时间
      sortType:2,//项目分组 1不分组；2分组
      projectList:[]
    }
  },
  components:{
    'tab-header':() => import('@/components/tab-header.vue'),
    'nav-select':() => import('@/components/nav-select.vue'),
    'time-button':() => import('@/components/time-button.vue'),
    'project-list':() => import('./projects/project-list.vue'),
    'empty-box':() => import('@/components/empty-box.vue'),
    'daqi-copyright':() => import('@/components/daqi-copyright')
  },
  watch:{
    timeParams:{
      handler(newVal,oldVal){
        if(! newVal){
          return
        }
        if(! ! oldVal && newVal.startTime === oldVal.startTime){
          return
        }
        this.getProjectsData()
      },
      deep:true,
      immediate:true,
    },
    categoryId:{
      handler(newVal,oldVal){
        if(! newVal){
          return
        }
        if(! ! oldVal && newVal === oldVal){
          return
        }
        this.getProjectsData()
      },
      deep:true,
    },
    'userStatus.curs_node_id_2':{
      deep:true,
      handler:'getProjectsData'
    }
  },
  computed:{
    ...mapState(['boss','userStatus'])
  },
  methods:{
    changeTabPage(index){
      this.$emit("changeTab",index)
      this.currentTab = index
    },
    /* 获取项目列表数据*/
    getProjectsData(){
      let {timeParams,sortType,categoryId} = this
      let {startTime,endTime} = timeParams
      let params = {
          type:sortType,
          commodity_category_name:categoryId.toString(),
          startTime,
          endTime
      }
      table.getProjectsData(params).then(res=>{
        this.projectList = res.data
        console.log(res.data)
      }).catch(err =>{
        console.log(err.info)
      })
     },
    /* 切换商品类型*/
    chooseNavItem(index){
      console.log(index)
      this.categoryId = index+1
    },
    /*切换时间*/
    timeChange(params){
      this.timeParams = params
    },
    /* 切换排序*/
    handleRank(index){
      let {sortType} = this
      if(index==0){
        this.sortType = sortType==1?2:1
        this.getProjectsData()
      }
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "~@/assets/style/public.scss";
@import './projects/assets/project-list.scss';

.container{
    display:flex;
    flex-direction:column;
    height:100%;
}
.list-container{
    flex:1;
    overflow-y: auto;
}
.rank-header{
    display:flex;
    background: #f5f5f7;
    padding:20px 0;
    li{
        @extend %ellipsis;
        line-height:1;
        font-size:21px;
        color:$small-font-color;
        padding-left: 30px;
        flex:1;
        &:first-child{
            width:300px;
        }
    }
}
.project-type-list{
    li{
        margin-top:20px;
        &:first-child{
            margin-top:0;
        }
    }
}

.type-box{
    background:#e9f3ff;
    border-top:$border-line-color 1px solid;
    .type-column{
        p{
            @extend %ellipsis;
            font-size:28px;
            color:$theme-main-color;
            font-weight:bold;
            padding:23px 0;
            line-height:1;
        }

    }
}

.placeholder-text{
    line-height:60px;
    height:60px;
    text-align:center;
    color:#666;
    font-size:26px;
}
.main-content{
  min-height:calc(100% - 101px);
 }
</style>
